@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  height: 100%;
}

.big-title-800 {
  font-family: Raleway;
  font-weight: 800;
  font-size: 60px;
}
.title-800-32 {
  font-family: Raleway;
  font-weight: 800;
  font-size: 25px;
  line-height: 40px;
}

.title-800 {
  font-family: Raleway;
  font-weight: 800;
  font-size: 40px;
}
.title-large-800 {
  font-family: Raleway;
  font-weight: 800;
  font-size: 60px;
}
.title-400 {
  font-family: Raleway;
  line-height: 1.6;
  font-size: 18px;
  font-weight: 400;
}

.title-700 {
  font-family: Raleway;
  font-weight: 700;
  font-size: 25px;
}
.title-700-20 {
  font-family: Raleway;
  font-weight: 700;
  font-size: 20px;
}
.title-small-spacing-700 {
  font-family: Raleway;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.25em;
}

.title-500 {
  font-family: Raleway;
  font-weight: 500;
  font-size: 26px;
}

.big-subtitle-500 {
  font-family: Raleway;
  font-weight: 500;
  font-size: 22px;
}

.subtitle-500 {
  font-family: Raleway;
  font-weight: 500;
  font-size: 14px;
}
.subtitle-700 {
  font-family: Raleway;
  font-weight: 700;
  font-size: 14px;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}
.Raleway {
  font-family: Raleway;
}
.color-grey {
  color: #74787e;
}

.color-black {
  color: #393e46;
}
.color-green {
  color: #28DF99;
}
.color-pinkred {
  color: #F30E5C;
}
.color-white {
  color: #fff;
}
.color-grey-mid {
  color: #B0B2B5;
}

.m-t-10 {
  margin-top: 10px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-t-50 {
  margin-top: 50px;
}

.m-l-5{
  margin-left: 5px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-l-50 {
  margin-left: 50px;
}

.m-r-10 {
  margin-right: 10px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-r-30 {
  margin-right: 30px;
}
.m-r-40 {
  margin-right: 40px;
}
.m-r-50 {
  margin-right: 50px;
}

.m-b-10 {
  margin-bottom: 10px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-b-100 {
  margin-bottom: 100px;
}
.m-t-100 {
  margin-top: 100px;
}
.m-b-150 {
  margin-bottom: 150px;
}
.p-h-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.p-h-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.p-h-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.horizontal-line {
  height: 1px;
  background: #d7d8da;
}
.flex-1{
  -webkit-flex: 1 1;
          flex: 1 1;
}
.display-flex {
  display: -webkit-flex;
  display: flex;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.r-c{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.r-c-c {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.r-sb-c {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.r-fe-c {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
.r-fs-c {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.r-ac {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.r-flex-start {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.c-cb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.c-jc {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}
.c-c-c {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.c-sb-c {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.cursor-pointer{
  cursor: pointer;
}

.ant-layout-header.header-layout {
  background: none;
}
.ant-layout.layout-body {
  background: none;
  /* position: sticky !important; */
}

.ant-layout-sider {
  background: none !important;
}



.problem-curl-img {
  right: 0;
  bottom: -28px;
  z-index: -100;
}

.problem-curl-img-mobile {
  right: 0;
  bottom: -16px;
  width: 100%;
  z-index: -1;
}

.solution-curl-wrap {
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -10px;
  z-index: -1000;
}

.solution-img-curl {
  width: 95%;
  position: absolute;
  right: 0;
  bottom: -15px;
  z-index: -1;
}

.accessible-curl {
  margin: auto;
  bottom: 12px;
  position: relative;
}

.carousel-arrow {
  background: #28df991c;
  color: #28DF99;
  padding: 10px;
  border-radius: 100px;
  font-size: 20px;
}

.unique-box-wrap {
  max-width: 500px;
  min-height: 340px;
  background: #FFFFFF;
  box-shadow: 0px 15px 10px -6px rgb(0 57 35 / 8%);
  padding: 40px;
}

.uniqueContainer {
  background: #EAFCF5;
  padding: 40px;
}

.you-interested {
  top: 0;
  left: 40px;
  right: 40px;
  bottom: 0;
}

.you-interested-mobile {
  top: 30px;
  left: 0px;
  right: 0px;
  bottom: 30px;
}

.ant-modal.home-request-modal {
  width: 800px !important;
}

.ant-modal.home-request-modal .ant-modal-header {
  display: none;
}

.ant-modal.home-request-modal .ant-modal-footer {
  display: none;
}

.ant-btn.home-modal-btn {
  border-radius: 6px;
}

.ant-btn.home-modal-btn:hover {
  border-color: #28DF99;
  color: #28DF99;
}

.ant-input.home-modal-input {
  border-radius: 6px;
}

.ant-input.home-modal-input:hover {
  border-color: #28DF99;
}

.ant-input.home-modal-input:focus {
  border-color: #28DF99;
  box-shadow: 0 0 0 2px #28df991c;
}

.ant-btn.home-modal-btn:focus {
  border-color: #28DF99;
  color: #28DF99;
  box-shadow: 0 0 0 2px #28df991c;
}

.ant-btn.home-modal-submit-btn {
  background: #28DF99;
  color: #fff;
  border-radius: 6px;
  border-color: transparent;
  padding: 0px 30px;
}

.ant-modal.home-request-modal .ant-modal-content {
  border-radius: 6px;
}

.home-modal-submit-btn.ant-btn:hover,
.ant-btn.home-modal-submit-btn:focus,
.ant-btn.home-modal-submit-btn:active {
  box-shadow: 0px 0px 15px 1px #28df9961;
  background: #28DF99;
  color: #fff;
  border-color: #28DF99;
}

.support-input {
  margin-top: 12px;
  border: 0.8px solid #D7D8DA;
  height: 56px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  padding: 0px 15px;
}

.PhoneInput.phone-input-wrapper .PhoneInputInput {
  border: none;
  outline: none;
}

.interested-wrap {
  margin: 100px -50px -160px;
}

@media only screen and (max-width: 480px) {
  .unique-box-wrap {
    padding: 20px;
  }

  .uniqueContainer {
    padding: 20px;
  }

  .interested-wrap {
    margin: 100px -15px -160px;
  }
}

.scroll-bar-universal {
  overflow: hidden;
}

.scroll-bar-universal:hover {
  overflow: auto;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.hideScroll {
  -ms-overflow-style: scroll;
  /* IE and Edge */
  scrollbar-width: none;
  /*fiReFoX */
}
.about-title-wrap {
  top: 0;
  z-index: -1;
}

.about-box-wrap {
  background: #FFFFFF;
  border: 0.8px solid #D7D8DA;
  box-sizing: border-box;
  border-radius: 6px;
  max-width: 335px;
  min-height: 370px;
  padding: 20px;
}

.about-title2-wrap {
  bottom: -8px;
  right: 0px;
  left: 6px;
  z-index: -1;
}

.vedio-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
}

.vedio-frame {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 5px;
}

.ceo-quote {
  min-width: 220px;
  padding: 15px;
  text-align: justify;
  z-index: 10001;
}

.ceo-left-quote {
  position: absolute;
  left: 0px;
  top: 40px;
  z-index: -1;
  width: 120px;
}

.ceo-right-quote {
  position: absolute;
  right: 0px;
  bottom: 50px;
  z-index: -1;
  width: 120px;
}

.ceo-quote-wrap {
  /* background: #ffff0052; */
  padding: 50px;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.aspect-box-wrap {
  background: #EAFCF5;
  padding: 40px;
  margin: 100px -50px 0px;
}

@media only screen and (max-width: 480px) {
  .aspect-box-wrap {
    background: #EAFCF5;
    padding: 20px;
    margin: 100px -15px 0px;
  }
}
.support-input{
  margin-top: 12px;
  border: 0.8px solid #D7D8DA;
  height: 56px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  padding: 0px 15px;
}
.PhoneInput.phone-input-wrapper .PhoneInputInput{
  border: none;
  outline: none;
}
.faq-header-wrapper{
  width: 100%;
  max-width: 536px;
  margin: 0 auto;
}
.faq-header-wave{
  position: absolute;
  right: 0px;
  z-index: -1;
  bottom: -30px;
}
.ant-collapse-borderless {
  background-color: #ffffff;
}

.digital-white-board-labels {
  background: #F8F8F8;
  border-radius: 100px;
  padding: 10px 20px;
}

.plannig-curl-wrap {
  z-index: -1;
  bottom: -5px;
}

.carousel-arrow {
  background: #28df991c;
  color: #28DF99;
  padding: 10px;
  border-radius: 100px;
  font-size: 20px;
}

.active-tab {
  width: 100%;
  /* height: 25%; */
  background-color: #D9D9D9;
  color: #594099 !important;
  font-weight: 600 !important;
}
.register-container {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.request-demo-wrapper{
  width: 100%;
  max-width: 800px;
  box-shadow: 0px 0px 8px -1px gainsboro;
  border-radius: 10px;
  padding: 20px 30px;
}
@media only screen and (max-width: 575px){
  .request-demo-wrapper{
    margin-top: 100px;
    padding: 10px;
  }
}

.body-padding{
  padding: 50px;
}
@media (max-width:480px) {
  .body-padding{
    padding: 50px 15px;
  }
}

