.digital-white-board-labels {
  background: #F8F8F8;
  border-radius: 100px;
  padding: 10px 20px;
}

.plannig-curl-wrap {
  z-index: -1;
  bottom: -5px;
}

.carousel-arrow {
  background: #28df991c;
  color: #28DF99;
  padding: 10px;
  border-radius: 100px;
  font-size: 20px;
}

.active-tab {
  width: 100%;
  /* height: 25%; */
  background-color: #D9D9D9;
  color: #594099 !important;
  font-weight: 600 !important;
}