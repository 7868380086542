.register-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.request-demo-wrapper{
  width: 100%;
  max-width: 800px;
  box-shadow: 0px 0px 8px -1px gainsboro;
  border-radius: 10px;
  padding: 20px 30px;
}
@media only screen and (max-width: 575px){
  .request-demo-wrapper{
    margin-top: 100px;
    padding: 10px;
  }
}
