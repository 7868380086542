.support-input{
  margin-top: 12px;
  border: 0.8px solid #D7D8DA;
  height: 56px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  padding: 0px 15px;
}
.PhoneInput.phone-input-wrapper .PhoneInputInput{
  border: none;
  outline: none;
}
.faq-header-wrapper{
  width: 100%;
  max-width: 536px;
  margin: 0 auto;
}
.faq-header-wave{
  position: absolute;
  right: 0px;
  z-index: -1;
  bottom: -30px;
}
.ant-collapse-borderless {
  background-color: #ffffff;
}
