.color-grey {
  color: #74787e;
}

.color-black {
  color: #393e46;
}
.color-green {
  color: #28DF99;
}
.color-pinkred {
  color: #F30E5C;
}
.color-white {
  color: #fff;
}
.color-grey-mid {
  color: #B0B2B5;
}
