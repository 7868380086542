

.problem-curl-img {
  right: 0;
  bottom: -28px;
  z-index: -100;
}

.problem-curl-img-mobile {
  right: 0;
  bottom: -16px;
  width: 100%;
  z-index: -1;
}

.solution-curl-wrap {
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -10px;
  z-index: -1000;
}

.solution-img-curl {
  width: 95%;
  position: absolute;
  right: 0;
  bottom: -15px;
  z-index: -1;
}

.accessible-curl {
  margin: auto;
  bottom: 12px;
  position: relative;
}

.carousel-arrow {
  background: #28df991c;
  color: #28DF99;
  padding: 10px;
  border-radius: 100px;
  font-size: 20px;
}

.unique-box-wrap {
  max-width: 500px;
  min-height: 340px;
  background: #FFFFFF;
  box-shadow: 0px 15px 10px -6px rgb(0 57 35 / 8%);
  padding: 40px;
}

.uniqueContainer {
  background: #EAFCF5;
  padding: 40px;
}

.you-interested {
  top: 0;
  left: 40px;
  right: 40px;
  bottom: 0;
}

.you-interested-mobile {
  top: 30px;
  left: 0px;
  right: 0px;
  bottom: 30px;
}

.ant-modal.home-request-modal {
  width: 800px !important;
}

.ant-modal.home-request-modal .ant-modal-header {
  display: none;
}

.ant-modal.home-request-modal .ant-modal-footer {
  display: none;
}

.ant-btn.home-modal-btn {
  border-radius: 6px;
}

.ant-btn.home-modal-btn:hover {
  border-color: #28DF99;
  color: #28DF99;
}

.ant-input.home-modal-input {
  border-radius: 6px;
}

.ant-input.home-modal-input:hover {
  border-color: #28DF99;
}

.ant-input.home-modal-input:focus {
  border-color: #28DF99;
  box-shadow: 0 0 0 2px #28df991c;
}

.ant-btn.home-modal-btn:focus {
  border-color: #28DF99;
  color: #28DF99;
  box-shadow: 0 0 0 2px #28df991c;
}

.ant-btn.home-modal-submit-btn {
  background: #28DF99;
  color: #fff;
  border-radius: 6px;
  border-color: transparent;
  padding: 0px 30px;
}

.ant-modal.home-request-modal .ant-modal-content {
  border-radius: 6px;
}

.home-modal-submit-btn.ant-btn:hover,
.ant-btn.home-modal-submit-btn:focus,
.ant-btn.home-modal-submit-btn:active {
  box-shadow: 0px 0px 15px 1px #28df9961;
  background: #28DF99;
  color: #fff;
  border-color: #28DF99;
}

.support-input {
  margin-top: 12px;
  border: 0.8px solid #D7D8DA;
  height: 56px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  padding: 0px 15px;
}

.PhoneInput.phone-input-wrapper .PhoneInputInput {
  border: none;
  outline: none;
}

.interested-wrap {
  margin: 100px -50px -160px;
}

@media only screen and (max-width: 480px) {
  .unique-box-wrap {
    padding: 20px;
  }

  .uniqueContainer {
    padding: 20px;
  }

  .interested-wrap {
    margin: 100px -15px -160px;
  }
}

.scroll-bar-universal {
  overflow: hidden;
}

.scroll-bar-universal:hover {
  overflow: auto;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.hideScroll {
  -ms-overflow-style: scroll;
  /* IE and Edge */
  scrollbar-width: none;
  /*fiReFoX */
}