.about-title-wrap {
  top: 0;
  z-index: -1;
}

.about-box-wrap {
  background: #FFFFFF;
  border: 0.8px solid #D7D8DA;
  box-sizing: border-box;
  border-radius: 6px;
  max-width: 335px;
  min-height: 370px;
  padding: 20px;
}

.about-title2-wrap {
  bottom: -8px;
  right: 0px;
  left: 6px;
  z-index: -1;
}

.vedio-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
}

.vedio-frame {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 5px;
}

.ceo-quote {
  min-width: 220px;
  padding: 15px;
  text-align: justify;
  z-index: 10001;
}

.ceo-left-quote {
  position: absolute;
  left: 0px;
  top: 40px;
  z-index: -1;
  width: 120px;
}

.ceo-right-quote {
  position: absolute;
  right: 0px;
  bottom: 50px;
  z-index: -1;
  width: 120px;
}

.ceo-quote-wrap {
  /* background: #ffff0052; */
  padding: 50px;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.aspect-box-wrap {
  background: #EAFCF5;
  padding: 40px;
  margin: 100px -50px 0px;
}

@media only screen and (max-width: 480px) {
  .aspect-box-wrap {
    background: #EAFCF5;
    padding: 20px;
    margin: 100px -15px 0px;
  }
}