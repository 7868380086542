.m-t-10 {
  margin-top: 10px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-t-50 {
  margin-top: 50px;
}

.m-l-5{
  margin-left: 5px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-l-50 {
  margin-left: 50px;
}

.m-r-10 {
  margin-right: 10px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-r-30 {
  margin-right: 30px;
}
.m-r-40 {
  margin-right: 40px;
}
.m-r-50 {
  margin-right: 50px;
}

.m-b-10 {
  margin-bottom: 10px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-b-100 {
  margin-bottom: 100px;
}
.m-t-100 {
  margin-top: 100px;
}
.m-b-150 {
  margin-bottom: 150px;
}
.p-h-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.p-h-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.p-h-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.horizontal-line {
  height: 1px;
  background: #d7d8da;
}
.flex-1{
  flex: 1;
}
.display-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.r-c{
  display: flex;
  flex-direction: column;
}
.r-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.r-sb-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.r-fe-c {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.r-fs-c {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.r-ac {
  display: flex;
  align-items: center;
}
.r-flex-start {
  display: flex;
  align-items: flex-start;
}
.c-cb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.c-jc {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.c-c-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.c-sb-c {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.cursor-pointer{
  cursor: pointer;
}
