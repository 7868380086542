.ant-layout-header.header-layout {
  background: none;
}
.ant-layout.layout-body {
  background: none;
  /* position: sticky !important; */
}

.ant-layout-sider {
  background: none !important;
}
